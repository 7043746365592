import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import LibraryContext from "../../context/Library.context";

const ModalCartErrorComponent = () => {
  const defaultErrorMessage =
    "Ops! Sembra che questo carrello sia stato precedentemente svuotato. Ti invitiamo a riprovare!";
  const { cartError, resetCartError } = useContext(LibraryContext);
  return (
    <Modal
      centered
      show={cartError}
      backdrop="static"
      keyboard={false}
      onHide={resetCartError}
    >
      <Modal.Header closeButton>
        <Modal.Title>Carrello vuoto!</Modal.Title>
      </Modal.Header>
      <Modal.Body>{defaultErrorMessage}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={resetCartError}>
          Ho Capito!
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalCartErrorComponent;
