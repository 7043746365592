import React, { useContext } from "react";
import { getPDF, getCSV } from "../../services/main.services";
import { deleteLocalStorage, retrieveLocalStorage } from "../../utils/utils";
import { FaFilePdf, FaFileCsv } from "react-icons/fa";
import LibraryContext from "../../context/Library.context";
import ErrorContext from "../../context/Error.context";

const ExportButton = ({ type, toggle }) => {
  const { setOverlayVisible } = useContext(LibraryContext);
  const { setGlobalError } = useContext(ErrorContext);
  const handleClick = () => {
    toggle();
    setOverlayVisible(true);

    const localStore = retrieveLocalStorage();
    if (type === "pdf") {
      getPDF({ id_param_url: localStore })
        .then((response) => downloadFile(response))
        .catch(async (err) => {
          const error = await err.response.data.text();
          const errorJson = await JSON.parse(error);
          const dataToContext = { data: errorJson };

          setGlobalError(dataToContext);
          deleteLocalStorage();
        })
        .finally(() => setOverlayVisible(false));
    } else if (type === "csv") {
      getCSV({ id_param_url: localStore })
        .then((response) => downloadFile(response))
        .catch(async (err) => {
          const error = await err.response.data.text();
          const errorJson = await JSON.parse(error);
          const dataToContext = { data: errorJson };

          setGlobalError(dataToContext);
          deleteLocalStorage();
        })
        .finally(() => setOverlayVisible(false));
    } else {
      console.log("Formato non supportato");
      setOverlayVisible(false);
    }
  };

  const downloadFile = (response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `Carrello.${type}`);
    document.body.appendChild(link);
    link.click();
  };

  return (
    <button onClick={handleClick} className="btn btn-secondary">
      {type === "pdf" ? (
        <FaFilePdf color="white" />
      ) : (
        <FaFileCsv color="white" />
      )}
      Esporta {type}
    </button>
  );
};

export default ExportButton;
