import React, { useState, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { isMobileSafari, isSafari } from "react-device-detect";

import LibraryContext from "../../context/Library.context";
import { retrieveLocalStorage } from "../../utils/utils";
import GroceryItem from "./GroceryItem.component";
import { FaStore } from "react-icons/fa";

const CompleteOrder = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { groceryList, checkCart } = useContext(LibraryContext);

  const toggle = () => setModalOpen((prev) => !prev);

  const items = groceryList
    ?.filter((el) => el.updated === true)
    .map((el) => (
      <GroceryItem
        key={el.skul}
        showOldPrice={true}
        showQuantity={false}
        el={el}
      />
    ));

  const completeOrder = () => {
    const localStore = retrieveLocalStorage();
    let link = `https://www.giodicart.it/vi/${localStore}`;
    toggle();

    if (isSafari) {
      setTimeout(() => {
        window.open(link, "_blank");
      });
    } else {
      const openTo = isMobileSafari ? "_self" : "_blank";
      window.open(`${link}`, openTo);
    }
  };

  return (
    <>
      <style type="text/css">
        {`
            .btn-completeOrder {
                background-color: rgb(114, 183, 56);
                color: white;
            }
        `}
      </style>
      <Button
        variant="completeOrder"
        onClick={() => {
          checkCart((res) => {
            if (res) {
              setModalOpen(true);
              return;
            }
            completeOrder();
          });
        }}
      >
        <FaStore className="me-2" />
        Completa ordine
      </Button>
      <Modal
        centered
        show={modalOpen && items.length > 0}
        backdrop="static"
        keyboard={false}
        onHide={toggle}
      >
        <Modal.Header closeButton>
          <Modal.Title>Attenzione</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column justify-content-around">
          <p>
            {` ${items.length} ${
              items.length > 1 ? "articoli" : "articolo"
            } nel tuo carrello ${items.length > 1 ? "hanno" : "ha"} subito una
          variazione di prezzo`}
          </p>
          {items}
        </Modal.Body>
        <Modal.Footer>
          <Button color="secondary" onClick={completeOrder}>
            Prosegui
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CompleteOrder;
