import React, { useContext } from "react";
import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import LibraryContext from "../../context/Library.context";
import { colors } from "../../theme/colors";
import { styles } from "./grocery.styles";

const GroceryItem = ({
  el: {
    image_file,
    description,
    price,
    currentPrice,
    price_label,
    field1,
    quantity,
    grocery_label,
    skul,
    old_price = -1,
  },
  showOldPrice = false,
  showQuantity = true,
  showDeleteCartItem = true,
}) => {
  const {
    updateQuantity,
    removeFromCart,
    groceryLoading,
    groceryLoadingSpecificItem,
  } = useContext(LibraryContext);

  const priceFormatter = !showOldPrice ? (
    <p>{currentPrice.toFixed(2)} €</p>
  ) : (
    <p>
      Prezzo Precedente <del>{old_price.toFixed(2)}</del> | Nuovo Prezzo{" "}
      {currentPrice.toFixed(2)}
    </p>
  );

  return (
    <div style={styles.itemContainer}>
      <img
        className="img-fluid"
        style={styles.image}
        src={image_file}
        alt={field1}
      />
      <div style={styles.infoContainer}>
        <p className="mt-3" style={styles.description}>
          {description}
        </p>
        {priceFormatter}
      </div>
      {!groceryLoadingSpecificItem[skul] ? (
        <>
          {showQuantity && (
            <div style={styles.quantityContainer} className="d-flex">
              <FaMinusCircle
                onClick={() =>
                  quantity > 1 ? updateQuantity(skul, "minus") : null
                }
                size={18}
                color={colors.primary_color}
              />
              <p style={styles.quantity}>
                {quantity} {grocery_label}
              </p>
              <FaPlusCircle
                onClick={() => updateQuantity(skul, "plus")}
                size={18}
                color={colors.primary_color}
              />
            </div>
          )}
          {showDeleteCartItem && (
            <div
              onClick={() => removeFromCart(skul)}
              style={styles.cartRemoveContainer}
            >
              <img
                className="img-fluid"
                src="https://view.interattivo.net/static/media/Cart_remove.4af7dc28.svg"
                alt="cart-remove"
              />
            </div>
          )}
        </>
      ) : (
        <div className="container-fluid d-flex" style={styles.loadingContainer}>
          <div className="spinner-grow" style={styles.spinner} role="status" />
        </div>
      )}
    </div>
  );
};

export default GroceryItem;
