import { createContext, useState } from "react";

const ErrorContext = createContext();

export const ErrorProvider = ({ children }) => {
  const initState = {
    errorCode: 0,
    modalErrorVisible: false,
    errorTitle: "",
    errorMessage: "",
  };

  const [errorState, setErrorState] = useState(initState);

  const resetError = () => {
    setErrorState(initState);
  };

  const setGlobalError = (payload) => {
    const {
      code: errorCode,
      data: { message: errorMessage, title: errorTitle, scope },
    } = payload.data;
    setErrorState({
      ...errorState,
      errorCode,
      modalErrorVisible: true,
      errorTitle: `${scope} ${errorTitle}`,
      errorMessage,
    });
  };

  return (
    <ErrorContext.Provider
      value={{ errorState, setErrorState, resetError, setGlobalError }}
    >
      {children}
    </ErrorContext.Provider>
  );
};

export default ErrorContext;
