import React from "react";
import { FaBookOpen } from "react-icons/fa";
import { styles } from "./leafletCard.styles";

function LeafletCardComponent({ element }) {
  const { name, publication_url, first_page_image } = element;
  const imgSrc = first_page_image
    ? first_page_image
    : "https://inpublish.interattivo.net/media/settings/project/client_icon/wa.jpg";
  return (
    <div className="col-md-4 ">
      <div style={styles.cardContainer} className="card d-flex text-center">
        <img
          style={styles.image}
          src={imgSrc}
          className="card-img-top shadow "
          alt="..."
        />
        <div className="card-body">
          <h5 style={styles.cardTitle} className="card-title text-center">
            {name}
          </h5>

          <a
            style={styles.linkStyle}
            href={publication_url}
            className="btn btn-primary customLinkStyle"
          >
            <FaBookOpen style={styles.icon} />
            SFOGLIA
          </a>
        </div>
      </div>
    </div>
  );
}

export default LeafletCardComponent;
