import React from "react";
import { styles } from "./header.styles";

function Header() {
  const logo =
    "https://inpublish.interattivo.net/media/settings/project/logo_full/Giodicart-WEB-sfondo-bianco.jpg";

  return (
    <div
      style={styles.headerContainer}
      className="navbar navbar-expand-lg d-flex shadow"
    >
      <img style={styles.logo} className="img-fluid" alt="logo" src={logo} />
    </div>
  );
}

export default Header;
