import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import LibraryContext from "../../context/Library.context";
import { colors } from "../../theme/colors";

const ModalConfirmClearCart = () => {
  const { resetModalClearCart, modalConfirmClearCart, actionClearCart } =
    useContext(LibraryContext);
  return (
    <>
      <style type="text/css">
        {`
        .btn-clientPrimary {
            background-color:${colors.secondary_color};
            color: white;
        }
    `}
      </style>

      <Modal
        centered
        show={modalConfirmClearCart}
        backdrop="static"
        keyboard={false}
        onHide={resetModalClearCart}
      >
        <Modal.Header closeButton>
          <Modal.Title>Conferma</Modal.Title>
        </Modal.Header>
        <Modal.Body>Sei sicuro di voler svuotare il tuo carrello? </Modal.Body>
        <Modal.Footer>
          <Button variant="clientPrimary" onClick={resetModalClearCart}>
            Annulla
          </Button>
          <Button variant="secondary" onClick={actionClearCart}>
            Si, sono sicuro
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalConfirmClearCart;
