import React from "react";
import Library from "./pages/Library.page";
import { LibraryProvider } from "./context/Library.context";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Cart from "./pages/Cart.page";
import { ErrorProvider } from "./context/Error.context";

const App = () => {
  return (
    <ErrorProvider>
      <LibraryProvider>
        <BrowserRouter basename={"library"}>
          <Routes>
            <Route path="/*" element={<Library />} />
            <Route path="/cart/:id_param_url" element={<Cart />} />
          </Routes>
        </BrowserRouter>
      </LibraryProvider>
    </ErrorProvider>
  );
};

export default App;
