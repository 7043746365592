import React, { useState, useContext } from "react";
import { FaCopy, FaShareAlt } from "react-icons/fa";
import {
  FacebookShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  TelegramIcon,
  WhatsappIcon,
} from "react-share";
import { isIOS, isFirefox } from "react-device-detect";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "react-notifications/lib/notifications.css";
import { styles } from "./grocery.styles";
import { NotificationManager } from "react-notifications";
import { retrieveLocalStorage } from "../../utils/utils";
import { colors } from "../../theme/colors";
import LibraryContext from "../../context/Library.context";

const ShareGroceryListBtn = () => {
  const [showModal, setShowModal] = useState(false);
  const { setCartError, setGroceryList } = useContext(LibraryContext);

  const link = () => {
    const localStorage = retrieveLocalStorage();
    return process.env.NODE_ENV === "development"
      ? `https://viewtest2.interattivo.net/library/cart/${localStorage}`
      : `https://viewtest2.interattivo.net/library/cart/${localStorage}`;
  };

  const openReactNativeShare = () => {
    let title = "Visualizza carrello";
    var str = title + "    " + link();
    var obj = { action: "whatsapp", value: str };
    window.ReactNativeWebView.postMessage(JSON.stringify(obj));
  };

  const shareMail = () => {
    let target = "_self";
    if (isFirefox) target = "_blank";
    window.open(`mailto:?body=${link()}`, target);
  };

  const openModal = () => {
    const localStorage = retrieveLocalStorage();
    if (!localStorage || localStorage === undefined || localStorage === "") {
      setCartError(true);
      setGroceryList([]);
      return;
    }
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
  };

  let title = "Visualizza lista spesa";

  let wa1 = "inline";
  let wa2 = "none";

  const copyToClip = () => {
    navigator.clipboard
      .writeText(link())
      .then(() =>
        NotificationManager.success(
          "Elemento Copiato",
          "Link copiato negli appunti",
          1000
        )
      )
      .catch((err) => {
        NotificationManager.error(
          "Errore nella copia",
          "ops qualcosa è andato storto,il link non è stato copiato!",
          1000
        );
      });
  };

  if (window.ReactNativeWebView !== undefined && isIOS) {
    wa1 = "none";
    wa2 = "inline";
  }

  const shareButtonStyle =
    true === 0
      ? {
          color: "white",
          fontWeight: "bold",
          display: "flex",
          justifyContent: "space-between",
          background: "grey",
        }
      : {
          color: "white",
          fontWeight: "bold",
          display: "flex",
          justifyContent: "space-between",
          cursor: "pointer",
        };

  let share_label = "Condividi carrello";

  return (
    <>
      <style type="text/css">
        {`
    .btn-flat {
      background-color: ${colors.secondary_color};
      color: white;
    }

  
    `}
      </style>
      <div style={shareButtonStyle} onClick={openModal}>
        <Button
          variant="flat"
          className="share-button d-flex align-items-center"
        >
          <FaShareAlt className="me-2" /> {share_label}
        </Button>
      </div>
      <Modal
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Condividi con...</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex custom-share-container">
          <FacebookShareButton
            className="facebookShareButton shareIcon"
            url={link()}
          >
            <FacebookIcon size={32} round={true} />
          </FacebookShareButton>

          <TwitterShareButton
            className="twitterShareIcon shareIcon"
            title={title}
            url={link()}
          >
            <TwitterIcon size={32} round={true} />
          </TwitterShareButton>

          <TelegramShareButton
            className="telegramShareIcon shareIcon"
            title={title}
            url={link()}
          >
            <TelegramIcon size={32} round={true} />
          </TelegramShareButton>

          <WhatsappShareButton
            className="whatsappShareIcon shareIcon"
            title={title}
            url={link()}
            style={{ display: wa1 }}
          >
            <WhatsappIcon size={32} round={true} />
          </WhatsappShareButton>

          <span style={{ display: wa2 }} onClick={openReactNativeShare}>
            <WhatsappIcon size={32} round={true} />
          </span>

          <div
            onClick={(e) => {
              e.preventDefault();
              shareMail();
            }}
            aria-label="email"
            role="button"
            tabIndex="0"
            className="SocialMediaShareButton SocialMediaShareButton--email emailShareIcon shareIcon"
          >
            <div style={{ width: "32px", height: "32px" }}>
              <svg
                viewBox="0 0 64 64"
                width="32"
                height="32"
                className="social-icon social-icon--email "
              >
                <g>
                  <circle cx="32" cy="32" r="31" fill="#7f7f7f"></circle>
                </g>
                <g>
                  <path
                    d="M17,22v20h30V22H17z M41.1,25L32,32.1L22.9,25H41.1z M20,39V26.6l12,9.3l12-9.3V39H20z"
                    fill="white"
                  ></path>
                </g>
              </svg>
            </div>
          </div>

          <div
            className="copyButton shareIcon"
            onClick={copyToClip}
            style={styles.copyButton}
          >
            <FaCopy color="white" />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ShareGroceryListBtn;
