import React, { useState, useContext } from "react";
import { styles } from "./grocery.styles";
import { FaShoppingCart } from "react-icons/fa";
import GroceryWrapper from "./GroceryWrapper.component";
import LibraryContext from "../../context/Library.context";

const Grocery = () => {
  const [visible, setVisible] = useState(false);
  const toggleVisibility = () => {
    if (groceryList.length > 0) {
      setVisible((prev) => !prev);
    }
  };

  const { groceryList } = useContext(LibraryContext);

  return (
    <div className="groceryContainer" style={styles.groceryContainer}>
      {visible && <GroceryWrapper setVisible={setVisible} visible={visible} />}
      <div
        onClick={toggleVisibility}
        className="btn btn-primary d-flex"
        style={styles.iconWrapper(groceryList.length > 0)}
      >
        <FaShoppingCart className="groceryIcon" size={20} color={"white"} />
        {groceryList.length > 0 && (
          <p style={styles.numberItem}>{groceryList.length}</p>
        )}
      </div>
    </div>
  );
};

export default Grocery;
