import { colors } from "../../theme/colors";

export const styles = {
  groceryContainer: {
    position: "fixed",
    width: "100%",
    bottom: 0,
    rigth: 20,
    display: "flex",
    justifyContent: "flex-end",
    padding: 25,
  },
  iconWrapper: (disabled) => {
    return {
      backgroundColor: !disabled ? "grey" : colors.secondary_color,
      borderRadius: 360,
      padding: 15,
      position: "absolute",
      height: "40px",
      width: "85px",
      bottom: "10px",
      justifyContent: "space-between",
      alignItems: "center",
      border: "none",
    };
  },
  groceryWrapper: {
    position: "absolute",
    rigth: 0,
    zIndex: 2,
  },
  wrapperContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    justifyContent: "space-between",
    border: "1px solid grey",
    zIndex: 3,
    position: "absolute",
    bottom: 0,
  },
  headerWrapper: {
    height: "80px",
    backgroundColor: colors.primary_color,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    color: "white",
    flex: 1,
  },
  totalCart: {
    backgroundColor: "red",
    width: "100%",
    height: "100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  totalCartText: {
    color: "white",
    textTransform: "capitalize",
    fontSize: "1.8rem",
    marginLeft: "10px",
  },
  closeIcon: {
    paddingRight: "15px",
  },
  image: {
    height: "90px",
    width: "90px",
  },
  itemContainer: {
    display: "flex",
    borderBottom: "1px solid #bababa",

    alignItems: "center",
  },
  cartRemoveContainer: {
    display: "flex",
    marginRight: "20px",
    marginLeft: "20px",
    flex: 0.15,
  },
  quantityContainer: {
    alignItems: "center",
    justifyContent: "space-around",
    height: "100%",

    flex: 0.45,
  },
  description: {
    textTransform: "uppercase",
    fontSize: "0.8rem",
  },
  quantity: {
    fontSize: "1.3rem",
    margin: 0,
  },
  wrapperItem: { height: "100%", overflowY: "scroll" },
  clearCart: {
    width: "40px",
    height: "40px",
    marginRight: "20px",
  },
  numberItem: {
    color: "white",
    margin: 0,
    fontWeight: "bold",
  },
  buttonContainer: {
    justifyContent: "space-around",
    padding: 15,
  },
  copyButton: {
    background: colors.secondary_color,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    borderRadius: "360px",
    width: "32px",
    height: "32px",
  },
  infoContainer: {
    flex: 1,
  },
};
