import React from "react";
import { styles } from "./loading.styles";

const Loading = ({ info = "", height }) => {
  return (
    <div
      className="container-fluid d-flex flex-column "
      style={styles.loadingContainer(height)}
    >
      <h3 className="text-white fw-bold">{info}</h3>
      <div className="spinner-grow" style={styles.spinner} role="status" />
    </div>
  );
};

export default Loading;
