import axios from "axios";

const apiUrl = process.env.REACT_APP_API_BASEURL;
const client_id = 137;

const API = axios.create({
  baseURL: apiUrl,
  validateStatus: function (status) {
    return status < 300;
  },
});

// RECUPERA PUBBLICAZIONI E CARRELLO
export const getMainContent = (id_param_url) =>
  API.post(`get-main-page`, {
    id_param_url: id_param_url,
    client_id: client_id,
  });

// RECUPERA CARRELLO
export const getCart = (data) => API.post(`read`, data);

// MODIFICA QUANTITà
export const updateQuantity = (id_param_url, skul, type) =>
  API.get(
    `update-quantity?id_param_url=${id_param_url}&skul=${skul}&type=${type}`
  );

// SVUOTA CARRELLO
export const clearCart = (data) => API.post(`clear-cart`, data);

// RIMUOVI ELEMENTO DA CARRELLO
export const removeFromCart = (data) => API.post(`delete-item-from-cart`, data);

// DUPLICA CARRELLO
export const cloneCart = (data) => API.post(`clone-cart`, data);

// OTTIENI PDF CARRELLO
export const getPDF = (data) =>
  API.post("get-pdf", data, { responseType: "blob" });

// OTTIENI CSV CARRELLO
export const getCSV = (data) =>
  API.post("get-csv", data, { responseType: "blob" });

// CONTROLLA COERENZA CARRELLO
export const checkCart = (data) =>
  axios.post(`${process.env.REACT_APP_API_BASEURL_GIODICART}check-cart`, data);
