import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { styles } from "./cart.styles";
import GroceryItem from "../grocery/GroceryItem.component";
import { Link, useParams } from "react-router-dom";
import Loading from "../common/Loading.component";
import { retrieveLocalStorage } from "../../utils/utils";

const CartComponent = ({
  showModal,
  handleClose,
  overwriteCart,
  cartItems,
  tempCartActual,
  tempCartNew,
  loaderModalCompare,
}) => {
  const items = cartItems?.map((el) => (
    <GroceryItem
      showQuantity={false}
      showDeleteCartItem={false}
      el={el}
      key={el.skul}
    />
  ));

  const param = useParams();

  const actualCart = tempCartActual?.map((el) => (
    <GroceryItem
      showQuantity={false}
      showDeleteCartItem={false}
      el={el}
      key={el.skul}
    />
  ));

  const newCart = tempCartNew?.map((el) => (
    <GroceryItem
      showQuantity={false}
      showDeleteCartItem={false}
      el={el}
      key={el.skul}
    />
  ));

  const totalCart = cartItems?.reduce((s, a) => {
    // let totalItemPrice = a.currentPrice * a.quantity;
    return s + a.currentPrice;
  }, 0);
  return (
    <>
      {!showModal && (
        <>
          {" "}
          <div className="row">
            <div
              style={styles.buttonTop}
              className="my-grocery-card btn br-2 rounded p-3 mt-2 text-center col-sm-12 col-md-6 offset-md-3"
            >
              <Link to="/">
                <h5>Torna alle pubblicazioni</h5>
              </Link>
            </div>
          </div>
          <div className="row ">
            <div className="col-sm-12 col-md-6 offset-md-3 scrollable-div">
              {items}
            </div>
          </div>
          <div className="row">
            <div
              style={styles.buttonTop}
              className="my-grocery-card btn br-2 rounded p-3 mt-2 text-center col-sm-12 col-md-6 offset-md-3"
            >
              <h5>Totale: €{totalCart.toFixed(2)}</h5>
            </div>
          </div>
        </>
      )}

      <Modal
        size="lg"
        centered
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Carrello già presente!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loaderModalCompare ? (
            <Loading height={"5vh"} />
          ) : (
            <div className="container-fluid">
              <div className="row ">
                <h4>
                  Scegli se mantenere il tuo carrello attuale oppure utilizzare
                  il nuovo carrello
                </h4>
                <div className="col col-lg-6 list-col">
                  Carrello Attuale - {retrieveLocalStorage()}
                  {actualCart}
                </div>
                <div className="col col-lg-6 list-col">
                  Nuovo Carrello - {param.id_param_url}
                  {newCart}
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Mantieni il vecchio carrello
          </Button>
          <Button onClick={overwriteCart} variant="primary">
            Utilizza nuovo carrello
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CartComponent;
