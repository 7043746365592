import { colors } from "../../theme/colors";

export const styles = {
  overlayWrapper: {
    backgroundColor: colors.overlay,
    zIndex: 5,
    position: "absolute",
    bottom: "0",
    top: "0",
    left: "0",
    right: "0",
  },
};
