import React from "react";
import { styles } from "./info.styles";

const Info = () => {
  return (
    <div className="d-flex" style={styles.wrapper}>
      <h2>Scopri i nostri cataloghi</h2>
      <div style={styles.separator} />
    </div>
  );
};

export default Info;
