import React, { useEffect, useState, useContext } from "react";
import CartComponent from "../components/cart/Cart.component";
import Header from "../components/common/Header";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteLocalStorage,
  retrieveLocalStorage,
  setLocalStorage,
  setLocalStorageItemAndCallback,
} from "../utils/utils";
import Loading from "../components/common/Loading.component";
import * as services from "../services/main.services";
import LibraryContext from "../context/Library.context";

const Cart = () => {
  const localStorage = retrieveLocalStorage();
  const param = useParams();

  const { compareCarts, tempCartActual, tempCartNew, loaderModalCompare } =
    useContext(LibraryContext);

  // CONTROLLO CHE IL PARAMETRO NON SIA UGUALE AL CARRELLO CHE HO IN LOCAL STORAGE
  const [showModal, setShowModal] = useState(
    localStorage !== null && localStorage !== param.id_param_url ? true : false
  );

  const [loading, setLoading] = useState(false);
  const [cartItems, setCartItems] = useState([]);

  let navigate = useNavigate();

  const handleClose = () => {
    setShowModal(false);
    navigate("/");
  };

  // CASO IN CUI NON HO LOCAL STORAGE OPPURE HO LOCAL STORAGE MA SONO SUL MIO CARRELLO
  const retrieveCart = () => {
    setLoading(true);
    setLocalStorage(param.id_param_url);
    services
      .getCart({ id_param_url: param.id_param_url })
      .then((res) => {
        console.log("res", res);
        const { data } = res;
        if (data) {
          setCartItems(data.data);
        }
      })
      .catch((err) => console.log("err", err))
      .finally(() => setLoading(false));
  };

  const createNewCart = () => {
    setLoading(true);
    const newCartIdParamUrl = retrieveLocalStorage();

    const oldCartIdParamUrl = retrieveLocalStorage(
      "old_id_param_url_giodicart"
    );

    services
      .cloneCart({
        old_id_param_url: oldCartIdParamUrl,
        new_id_param_url: newCartIdParamUrl,
        clone_id_param_url: param.id_param_url,
      })
      .then((res) => {
        const { data } = res;

        if (data.status === 200) {
          deleteLocalStorage("old_id_param_url_giodicart");

          window.open(process.env.REACT_APP_REDIRECT, "_self");

          // setTimeout(() => {

          // navigate("/", { replace: true });
          // }, 2000);
        }
      });
  };

  //SE LA MODALE é APERTA ALLORA AVEVO GIà UN CARRELLO, PREMO BOTTONE CONTINUA SU MODALE , QUINDI SOVRASCRIVO IL MIO ATTUALE CARRELLO
  const overwriteCart = () => {
    setShowModal(false);
    setLoading(true);

    setLocalStorageItemAndCallback(createNewCart);
  };

  useEffect(() => {
    // SE LA MODALE NON COMPARE VUOL DIRE CHE IL MIO LOCAL STORAGE è VUOTO OPPURE IL MIO LOCAL STORAGE é UGUALE AL PARAMETRO NELL'URL
    console.log(param.id_param_url);
    if (localStorage === null || localStorage === param.id_param_url) {
      console.log("LA MODALE NON é VISIBILE");
      //SE IL LOCAL STORAGE é NULL DEVO CHIAMARE LA CLONE CART, DUPLICO IL CARRELLO E HO UN NUOVO PARAMETRO IN LOCAL STORAGE
      if (localStorage === null) {
        console.log("LOCAL STORAGE é NULL");
        setLocalStorageItemAndCallback(createNewCart, false);
      } else {
        console.log("LOCAL STORAGE è PIENO");
        retrieveCart();
      }
    } else {
      console.log("LA MODALE é VISIBILE");
      compareCarts(param.id_param_url);
    }
  }, []);

  if (loading)
    return (
      <>
        <Header />
        <Loading />
      </>
    );

  return (
    <>
      <Header />
      <CartComponent
        tempCartActual={tempCartActual}
        tempCartNew={tempCartNew}
        loaderModalCompare={loaderModalCompare}
        cartItems={cartItems}
        handleClose={handleClose}
        overwriteCart={overwriteCart}
        showModal={showModal}
      />
    </>
  );
};

export default Cart;
