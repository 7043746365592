import { colors } from "../../theme/colors";

export const styles = {
  loadingContainer: (height = "100vh") => {
    return {
      height: height,
      justifyContent: "center",
      alignItems: "center",
    };
  },
  spinner: { width: "8rem", height: "8rem", color: colors.secondary_color },
};
