import { colors } from "../../theme/colors";

export const styles = {
  wrapper: {
    justifyContent: "center",
    textTransform: "uppercase",
    color: colors.primary_color,
    marginTop: "50px",
    marginBottom: "50px",
    flexDirection: "column",
    alignItems: "center",
  },
  separator: {
    height: "5px",
    marginTop: "10px",
    width: "200px",
    backgroundColor: colors.secondary_color,
  },
};
