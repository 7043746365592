import React, { useContext } from "react";
import Header from "../components/common/Header";
import Info from "../components/common/Info";
import Loading from "../components/common/Loading.component";
import ModalConfirmClearCart from "../components/common/ModalClearCart.component";
import ModalCartErrorComponent from "../components/common/ModalCartError.component";
import Overlay from "../components/common/Overlay.component";
import Error from "../components/error/Error.component";
import Grocery from "../components/grocery/Grocery.component";
import LibraryComponent from "../components/library/Library.component";
import LibraryContext from "../context/Library.context";
import ErrorContext from "../context/Error.context";
import ModalGlobalError from "../components/common/ModalGlobalError.component";

const Library = () => {
  const { loading, error, overlayVisible, cartError, modalConfirmClearCart } =
    useContext(LibraryContext);
  const {
    errorState: { modalErrorVisible },
  } = useContext(ErrorContext);

  if (loading) return <Loading />;

  if (!loading && error?.status) return <Error />;

  return (
    <>
      <Header />
      <Info />
      <LibraryComponent />
      <Grocery />
      {overlayVisible && <Overlay />}
      {cartError && <ModalCartErrorComponent />}
      {modalConfirmClearCart && <ModalConfirmClearCart />}
      {modalErrorVisible && <ModalGlobalError />}
    </>
  );
};

export default Library;
