import React from "react";

function Error() {
  return (
    <div>
      <h3>Ops qualcosa è andato storto</h3>
    </div>
  );
}

export default Error;
