import { createContext, useState, useEffect, useContext } from "react";
import { deleteLocalStorage, retrieveLocalStorage } from "../utils/utils";
import * as services from "../services/main.services";
import ErrorContext from "./Error.context";

const LibraryContext = createContext();

export const LibraryProvider = ({ children }) => {
  const [leaflets, setLeaflets] = useState([]);
  const [groceryList, setGroceryList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState({
    status: false,
    errorMessage: "",
  });

  const { errorState, setGlobalError } = useContext(ErrorContext);
  console.log(
    "🚀 ~ file: Library.context.js ~ line 18 ~ LibraryProvider ~ errorState",
    errorState
  );

  const [modalConfirmClearCart, setModalConfirmClearCart] = useState(false);

  const [cartError, setCartError] = useState(false);
  const [groceryLoadingSpecificItem, setGroceryLoadingSpecificItem] = useState(
    {}
  );
  const [groceryLoading, setGroceryLoading] = useState(false);
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [tempCartActual, setTempCartActual] = useState([]);
  const [tempCartNew, setTempCartNew] = useState([]);
  const [loaderModalCompare, setLoaderModalCompare] = useState(true);
  const [idListino, setIdListino] = useState("");

  // RECUPERO INFORMAZIONI RELATIVE A CARRELLO E PUBBLICAZIONI
  const retrieveLeafLet = () => {
    const userGrocery = retrieveLocalStorage();

    services
      .getMainContent(userGrocery)
      .then((res) => {
        const { data } = res.data;

        if (data) {
          setIdListino(data.id_listino);
          setLeaflets(data.leaflets);
          setGroceryList(data.grocery_list);
          const obj = Object.fromEntries(
            data.grocery_list.map((item, i) => [item.skul, false])
          );
          setGroceryLoadingSpecificItem(obj);
        }
      })
      .catch((err) => {
        setGlobalError(err.response);
        deleteLocalStorage();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // TOTALE CARRELLO
  const totalCart = groceryList?.reduce((s, a) => {
    return s + a.currentPrice;
  }, 0);

  // AZIONE PER CONTROLLO VALIDITà CARRELLO (ID PARAM URL NON PRESENTE)
  const checkCartValidity = () => {
    const userGrocery = retrieveLocalStorage();
    let valid = false;
    if (!userGrocery || userGrocery === undefined || userGrocery === "") {
      setCartError(true);
      setGroceryList([]);
    } else {
      valid = true;
    }
    return valid;
  };

  // UPDATE QUANTITY IN GROCERY
  const updateQuantity = (skul, type) => {
    const userGrocery = retrieveLocalStorage();

    const validCart = checkCartValidity();
    if (validCart) {
      let temp_obj = { ...groceryLoadingSpecificItem };
      temp_obj[skul] = true;

      setGroceryLoadingSpecificItem(temp_obj);

      services
        .updateQuantity(userGrocery, skul, type === "plus" ? 1 : -1)
        .then((res) => {
          const { data } = res;

          if (data) {
            let newGrocery = groceryList.map((el) =>
              el.skul === skul ? data.data[0] : el
            );
            setGroceryList(newGrocery);
            let temp_obj = { ...groceryLoadingSpecificItem };

            //LOADER PER SINGOLO ITEM
            Object.keys(temp_obj).forEach((el) => (temp_obj[el] = false));

            setGroceryLoadingSpecificItem(temp_obj);

            setGroceryLoading(false);
          }
        })
        .catch((err) => {
          if (err) {
            setGlobalError(err.response);

            if (err.response.data.code === 400) {
              deleteLocalStorage();
              setGroceryList([]);
            } else {
              removeFromCart(skul);
            }
          }
        });
    }
  };

  // REMOVE ITEM FROM GROCERY
  const removeFromCart = (skul) => {
    const userGrocery = retrieveLocalStorage();
    const validCart = checkCartValidity();

    if (validCart) {
      setGroceryLoading(true);

      services
        .removeFromCart({ id_param_url: userGrocery, skul: skul })
        .then((res) => {
          const { data } = res;
          if (data.status === 200) {
            let newGrocery = groceryList.filter((el) => el.skul !== skul);
            if (data.data.length === 0) {
              deleteLocalStorage();
            }

            setGroceryList(newGrocery);
          }
        })
        .catch((err) => {
          setGlobalError(err.response);
        })
        .finally(setGroceryLoading(false));
    }
  };

  // AZIONE EFFETTIVA DI RIMOZIONE CARRELLO
  const actionClearCart = () => {
    setModalConfirmClearCart(false);
    const userGrocery = retrieveLocalStorage();
    const validCart = checkCartValidity();
    if (validCart) {
      setGroceryLoading(true);
      services
        .clearCart({ id_param_url: userGrocery })
        .then((res) => {
          const { data } = res;
          if (data.status === 200) {
            setGroceryList([]);
            deleteLocalStorage();
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setGroceryLoading(false));
    }
  };

  // CONFERMA SVUOTA CARRELLO
  const clearCart = () => {
    const validCart = checkCartValidity();

    if (validCart) {
      setModalConfirmClearCart(true);
    }
  };

  // CONTROLLO CHE I PREZZI A CARRELLO SIANO GLI ULTIMI PRESENTI IN DB
  const checkCart = (callback) => {
    const userGrocery = retrieveLocalStorage();
    const validCart = checkCartValidity();

    if (validCart) {
      services
        .checkCart({ id_param_url: userGrocery, id_listino: idListino })
        .then((res) => {
          const { data } = res;
          if (data) {
            setGroceryList(data.data[0]);
          }
          let updated = false;
          let counter = 0;

          while (!updated && counter < data.data[0].length) {
            if (data.data[0][counter].updated) updated = true;

            counter++;
          }

          callback(updated);
        })
        .catch((err) => {
          setGlobalError(err.response);
          setGroceryList([]);
          deleteLocalStorage();
        });
    }
  };

  // MOSTRA COMPARAZIONE CARRELLI
  const compareCarts = (param) => {
    const actualCartIdParamUrl = retrieveLocalStorage();
    const validCart = checkCartValidity();

    if (validCart) {
      const newCartIdParamUrl = param;

      Promise.all([
        services.getCart({ id_param_url: actualCartIdParamUrl }),
        services.getCart({ id_param_url: newCartIdParamUrl }),
      ])
        .then(([actualCart, newCart]) => {
          const { data: data1 } = actualCart;
          const { data: data2 } = newCart;
          if (data1 && data2) {
            setTempCartActual(data1.data);
            setTempCartNew(data2.data);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setLoaderModalCompare(false));
    }
  };

  const resetCartError = () => {
    setCartError(false);
  };

  const resetModalClearCart = () => {
    setModalConfirmClearCart(false);
  };

  useEffect(() => {
    retrieveLeafLet();
  }, []);

  return (
    <LibraryContext.Provider
      value={{
        leaflets,
        groceryList,
        loading,
        error,
        cartError,
        groceryLoading,
        totalCart,
        tempCartActual,
        groceryLoadingSpecificItem,
        overlayVisible,
        tempCartNew,
        loaderModalCompare,
        modalConfirmClearCart,
        setOverlayVisible,
        checkCart,
        compareCarts,
        updateQuantity,
        removeFromCart,
        clearCart,
        setGroceryList,
        resetCartError,
        setCartError,
        resetModalClearCart,
        actionClearCart,
      }}
    >
      {children}
    </LibraryContext.Provider>
  );
};

export default LibraryContext;
