export const id_campagna = "giodicart";

export const retrieveLocalStorage = (el) => {
  if (!el || el === "" || el === null) {
    el = "id_param_url_giodicart";
  }
  const grocery = JSON.parse(localStorage.getItem(el));
  return grocery;
};

export const deleteLocalStorage = (el) => {
  if (!el || el === "" || el === null) {
    el = "id_param_url_giodicart";
  }
  localStorage.removeItem(el);
};

export const idParamUrlGenerator = () => {
  return Math.random().toString(36).slice(2, 13);
};

export const setLocalStorage = (param) => {
  localStorage.setItem("id_param_url_giodicart", JSON.stringify(param));
};

export const setLocalStorageItemAndCallback = (callback, deleteOld = true) => {
  if (deleteOld) {
    const oldIdParamUrl = retrieveLocalStorage();
    localStorage.setItem(
      "old_id_param_url_giodicart",
      JSON.stringify(oldIdParamUrl)
    );
  }

  localStorage.setItem(
    "id_param_url_giodicart",
    JSON.stringify(idParamUrlGenerator())
  );
  callback();
};
