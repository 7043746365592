import React, { useState, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ExportButton from "./ExportButton";
import { FaFileExport } from "react-icons/fa";
import LibraryContext from "../../context/Library.context";
import { retrieveLocalStorage } from "../../utils/utils";
const ExportWrapper = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const { setCartError, setGroceryList } = useContext(LibraryContext);
  const toggle = () => {
    const localStorage = retrieveLocalStorage();
    if (!localStorage || localStorage === undefined || localStorage === "") {
      setCartError(true);
      setGroceryList([]);
      return;
    }
    setModalOpen((prev) => !prev);
  };
  return (
    <>
      <Button onClick={toggle}>
        <FaFileExport color="white" /> Esporta
      </Button>
      <Modal
        centered
        show={modalOpen}
        backdrop="static"
        keyboard={false}
        onHide={toggle}
      >
        <Modal.Header closeButton>Esporta carrello</Modal.Header>
        <Modal.Body className="d-flex justify-content-around">
          <ExportButton toggle={toggle} type="pdf" />
          <ExportButton toggle={toggle} type="csv" />
        </Modal.Body>
        {/* <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter> */}
      </Modal>
    </>
  );
};

export default ExportWrapper;
