import React from "react";
import Loading from "./Loading.component";
import { styles } from "./overlay.styles";

const Overlay = () => {
  return (
    <div
      className="d-flex justify-content-center align-items-center flex-column"
      style={styles.overlayWrapper}
    >
      <Loading info="Generazione Pdf in Corso" />
    </div>
  );
};

export default Overlay;
