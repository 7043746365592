import React, { useContext } from "react";
import LibraryContext from "../../context/Library.context";
import LeafletCardComponent from "./LeafletCard.component";

const LibraryComponent = () => {
  const { leaflets } = useContext(LibraryContext);

  const renderItem = leaflets?.map((el) => (
    <LeafletCardComponent key={el.id} element={el} />
  ));
  return (
    <div className="container">
      <div className="row">{renderItem}</div>
    </div>
  );
};

export default LibraryComponent;
