import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import ErrorContext from "../../context/Error.context";

const ModalGlobalError = () => {
  const { errorState, resetError } = useContext(ErrorContext);
  return (
    <Modal
      centered
      show={errorState?.modalErrorVisible}
      backdrop="static"
      keyboard={false}
      onHide={resetError}
    >
      <Modal.Header closeButton>
        <Modal.Title>{errorState?.errorTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{errorState?.errorMessage}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={resetError}>
          Ho Capito!
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalGlobalError;
