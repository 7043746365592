import React, { useContext } from "react";
import { FaTimes } from "react-icons/fa";
import LibraryContext from "../../context/Library.context";
import Loading from "../common/Loading.component";
import CompleteOrder from "./CompleteOrder";
import ExportWrapper from "./ExportWrapper";
import { styles } from "./grocery.styles";
import GroceryItem from "./GroceryItem.component";
import ShareGroceryList from "./ShareGroceryListBtn.component";

const GroceryWrapper = ({ setVisible }) => {
  const { totalCart, groceryList, clearCart, groceryLoading } =
    useContext(LibraryContext);

  const items = groceryList?.map((el) => <GroceryItem el={el} key={el.skul} />);

  return (
    <>
      <style type="text/css">
        {`
    .btn-completeOrder {
      background-color: rgb(114, 183, 56);
      color: white;
    }

  
    `}
      </style>

      <div className="wrapperContainer" style={styles.wrapperContainer}>
        <div style={styles.headerWrapper}>
          <h3 style={styles.title} className="text-center">
            Il tuo carrello
          </h3>

          <FaTimes
            onClick={() => setVisible(false)}
            style={styles.closeIcon}
            size={30}
            color={"white"}
          />
        </div>
        {groceryLoading ? (
          <Loading />
        ) : (
          <div style={styles.wrapperItem}>{items}</div>
        )}

        <div>
          {groceryList.length > 0 && (
            <div className="d-flex" style={styles.buttonContainer}>
              <ShareGroceryList />
              <CompleteOrder />
              <ExportWrapper />
            </div>
          )}

          <div style={styles.totalCart}>
            <p style={styles.totalCartText}>totale: €{totalCart.toFixed(2)}</p>
            {groceryList.length > 0 && (
              <div onClick={clearCart}>
                <img
                  style={styles.clearCart}
                  className="img-fluid"
                  src="https://view.interattivo.net/static/media/Cart_delete.3e6ffeed.svg"
                  alt="svuota carrello"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default GroceryWrapper;
