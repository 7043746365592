import { colors } from "../../theme/colors";

export const styles = {
  cardTitle: {
    textTransform: "capitalize",
    color: colors.primary_color,
  },
  image: {
    maxWidth: "300px",
  },
  cardContainer: {
    justifyContent: "center",
    alignItems: "center",
    border: "none",
  },
  linkStyle: {
    backgroundColor: colors.color_primary,
  },
  icon: {
    marginRight: "20px",
  },
};
